import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import React, { useEffect, lazy } from "react";
import { useDispatch, useSelector } from "react-redux";

import { clearMessage } from "../../../redux/actions/message";

import ProtectedRoutes from "../../../routes/ProtectedRoutes";
import PublicRoutes from "../../../routes/PublicRoutes";
import Notifications from "../../Notifications";
import TrackChanges from "../../Dashboard/TrackChanges";
import ViewAccount from "../../Dashboard/ViewAccount";

const Login = lazy(() => import("../../Login/"));
const Dashboard = lazy(() => import("../../Dashboard/"));
const AddAccount = lazy(() => import("../../Dashboard/AddAccount"));
const ForgotPassword = lazy(() => import("../../ForgotPassword/"));
const ResetPassword = lazy(() => import("../../ResetPassword/"));

const ListtaxPro = lazy(() => import("../../Taxpros/ListTaxPro"));
const AddTaxpros = lazy(() => import("../../Taxpros"));
const AddAccountInformation = lazy(
  () => import("../../Dashboard/AddAccountInformation")
);
const RequestTranscripts = lazy(
  () => import("../../Dashboard/RequestTranscripts")
);

const AppMain = () => {
  let isLoggedIn = false;
  const { user: currentUser } = useSelector((state) => state.auth);
  if (currentUser) {
    isLoggedIn = true;
  }
  const dispatch = useDispatch();
  let location = useLocation();

  useEffect(() => {
    if (["/login"].includes(location.pathname)) {
      dispatch(clearMessage()); // clear message when changing location
    }
  }, [dispatch, location]);

  return (
    <Routes>
      <Route element={<ProtectedRoutes isLoggedIn={isLoggedIn} />}>
        <Route path="taxpros" key="taxpros">
          <Route
            path="list"
            key="list"
            element={<ListtaxPro currentUser={currentUser} />}
          />

          <Route
            path="add"
            key="add"
            element={<AddTaxpros currentUser={currentUser} />}
          />
          <Route path=":id" element={<AddTaxpros />} />
        </Route>
        <Route path="account">
          <Route
            path="add"
            element={<AddAccount currentUser={currentUser} />}
          />
          <Route path="edit" element={<ViewAccount />} />
          <Route path="list" key="list" element={<Dashboard />} />
          <Route path="track-changes/:id" element={<TrackChanges />}></Route>
          <Route path=":id" element={<AddAccount />}>
            <Route index element={<AddAccountInformation />} />
            <Route path="request-transcript" element={<RequestTranscripts />} />
          </Route>
        </Route>
        <Route path="notification">
          <Route path="view" element={<Notifications />} />
        </Route>
      </Route>

      <Route element={<PublicRoutes isLoggedIn={isLoggedIn} />}>
        <Route path="login" element={<Login />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="reset-password" element={<ResetPassword />} />
        <Route path="/login" element={<Login />} />
        <Route
          path="*"
          element={<Navigate to={isLoggedIn ? "/account/list" : "/login"} />}
        />
      </Route>
    </Routes>
  );
};

export default AppMain;
