// export const downloadfromblob = (filePath, response) => {
// }

import { pdfHeader } from "../redux/services/auth-header";

// Handler for file download
export async function downloadFromUrl(filePath) {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}files/${filePath}`,
    {
      method: "GET",
      headers: pdfHeader(),
    }
  );

  if (!response.ok) {
    // throw new Error("Network response was not ok");
    return;
  }

  // Convert the data to a blob
  const blob = await response.blob();

  // Create a link to download
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", filePath); // Set the download attribute to the filename
  document.body.appendChild(link);
  link.click();

  // Clean up and revoke the URL
  link.parentNode.removeChild(link);
  window.URL.revokeObjectURL(url);
}

export async function downloadSingleTranscript(id, filePath, urls) {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}${urls}/${id}`,
    {
      method: "GET",
      headers: pdfHeader(),
    }
  );

  if (!response.ok) {
    // throw new Error("Network response was not ok");
    return;
  }

  // Convert the data to a blob
  const blob = await response.blob();

  // Create a link to download
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", filePath); // Set the download attribute to the filename
  document.body.appendChild(link);
  link.click();

  // Clean up and revoke the URL
  link.parentNode.removeChild(link);
  window.URL.revokeObjectURL(url);
  return response;
}

export async function downloadFromUrlCSV(filePath, urls) {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}${urls}/${filePath}`,
    {
      method: "GET",
      headers: pdfHeader(),
    }
  );

  if (!response.ok) {
    // throw new Error("Network response was not ok");
    return;
  }

  // Convert the data to a blob
  const blob = await response.blob();

  // Create a link to download
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `account_${filePath}_changeLogs.csv`); // Set the download attribute to the filename
  document.body.appendChild(link);
  link.click();

  // Clean up and revoke the URL
  link.parentNode.removeChild(link);
  window.URL.revokeObjectURL(url);
}
