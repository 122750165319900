import axios from "axios";
import { authHeader } from "./auth-header";

const getAllTrackChanges = (publicId, search, page, perPage, active) => {
  return axios.get(
    process.env.REACT_APP_API_URL +
      `changeLog/list/${publicId}?all=true&keyword=${search}&page=${page}&perPage=${perPage}&isActive=${active}`,
    {
      headers: authHeader(),
      data: {},
    }
  );
};
const downloadTrackChanges = (publicId) => {
  return axios.get(
    process.env.REACT_APP_API_URL + `changeLog/download/${publicId}`,
    {
      headers: authHeader(),
      responseType: "blob",
    }
  );
};
export const trackChange = { getAllTrackChanges, downloadTrackChanges };
