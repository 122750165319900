/* eslint-disable no-case-declarations */
import {
  ADD_TRANSCRIPTREQUEST_LOCAL,
  CLEARTRANSCRIPT,
  CREATE_TRANSCRIPTREQUEST,
  DELETE_COMBINED_TRANSCRIPT,
  DOWNLOADFILE_REQUEST,
  EXTRACTALLTRANSCRIPT,
  MAILBOX_REQUEST,
  RETRIEVE_COMBINED_TRANSCRIPTREQUEST,
  RETRIEVE_TRANSCRIPT_FAILURE,
  RETRIEVE_TRANSCRIPT_REQUEST,
  RETRIEVE_TRANSCRIPT_SUCCESS,
  UPDATE_TRANSCRIPT,
} from "../actions/types";

const initialState = {
  totalCount: 0,
  tranScriptDetails: [],
  createdTranScript: {},
  selectedTranScript: {},
  loading: false,
  error: null,
  hasMore: true,
};

const transcriptReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case RETRIEVE_TRANSCRIPT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case CREATE_TRANSCRIPTREQUEST:
      // Handles creating a new transcript request
      // Updates existing transcript details if matching criteria are found
      // Adds new transcript details if no matching criteria are found
      return {
        ...state,
        createdTranScript: payload?.data,
        tranScriptDetails: state.tranScriptDetails?.map((item) => {
          if (
            (item.formNumber ===
              payload?.data?.transcriptResults[0]?.formNumber ||
              item.taxForm ===
                payload?.data?.transcriptResults[0]?.formNumber) &&
            item.taxFormTypeId ===
              payload?.data?.transcriptResults[0]?.taxFormTypeId &&
            item.taxYear === payload?.data?.transcriptResults[0]?.taxYear &&
            item.taxPeriod == payload?.data?.transcriptResults[0]?.taxPeriod
          ) {
            return { ...item, ...payload?.data?.transcriptResults[0] };
          } else {
            return item;
          }
        }),
      };

    case RETRIEVE_TRANSCRIPT_SUCCESS:
      // Handles retrieving transcript requests
      return {
        ...state,
        totalCount: payload?.transcriptDetails?.count,
        tranScriptDetails: payload.transcriptDetails.rows,
        loading: false,
        hasMore: payload?.count > state.tranScriptDetails.length,
      };
    case RETRIEVE_TRANSCRIPT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case RETRIEVE_COMBINED_TRANSCRIPTREQUEST:
      // Handles retrieving combined transcript requests
      return {
        ...state,
      };

    case ADD_TRANSCRIPTREQUEST_LOCAL:
      // Handles adding local transcript requests
      // Filters out existing transcript details and adds new ones
      // eslint-disable-next-line no-case-declarations
      const newItems = payload.filter(
        (item) =>
          !state.tranScriptDetails.some(
            (transcript) =>
              (item.formNumber
                ? transcript.taxForm === item.formNumber
                : transcript.taxForm === item.taxForm) &&
              transcript.taxYear === item.taxYear &&
              transcript.productType === item.productType &&
              transcript.purposeType === item.purposeType &&
              transcript.taxFormTypeId === item.taxFormTypeId &&
              Number(transcript.taxPeriod) === Number(item.taxPeriod)
          )
      );

      return {
        ...state,
        totalCount: state.totalCount + newItems.length,
        selectedTranScript: payload,
        tranScriptDetails: [...newItems, ...state.tranScriptDetails],
      };

    case EXTRACTALLTRANSCRIPT:
      // Handles extracting all transcript requests
      return {
        ...state,
      };

    case CLEARTRANSCRIPT:
      // Handles clearing transcript details
      return {
        ...state,
        tranScriptDetails: [],
      };

    case MAILBOX_REQUEST:
      // Handles mailbox requests
      return {
        ...state,
      };

    case DOWNLOADFILE_REQUEST:
      // Handles download file requests
      return {
        ...state,
      };

    case DELETE_COMBINED_TRANSCRIPT:
      // Handles deleting combined transcript requests
      return {
        ...state,
      };

    case UPDATE_TRANSCRIPT:
      const {
        formNumber,
        taxFormTypeId,
        taxYear,
        taxPeriod,
        status,
        message,
        lastChecked,
      } = payload;
      return {
        ...state,
        tranScriptDetails: state.tranScriptDetails.map((transcript) => {
          if (
            transcript.formNumber === formNumber &&
            transcript.taxFormTypeId === taxFormTypeId &&
            transcript.taxYear === taxYear &&
            transcript.taxPeriod === taxPeriod
          ) {
            // If the transcript matches, update its status
            return { ...transcript, status, message, lastChecked };
          } else {
            // Otherwise, keep it unchanged
            return transcript;
          }
        }),
      };

    default:
      return state;
  }
};

export default transcriptReducer;
